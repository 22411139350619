import styled, { css } from 'styled-components';
import { Highlight } from 'react-instantsearch';
// import { Link } from 'react-router-dom';

import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { HitProps, InstantSearchProps } from './InstantSearch.types';
import { H4 } from '../../atoms/Typography';
import { Link } from '../../atoms/Button';
import { Pill, PillsList } from '../../atoms/Pill/Pill';
import { Spacer } from '../../atoms/Spacer/Spacer';

export const Container = styled.div<InstantSearchProps>`
  padding: 16px;
  border: 1px solid ${Colors.PrimaryLightest};
`;

export function Hit({ hit }: HitProps) {
  const url = `/jobPosts/user/${hit.userUuid}/job/${hit.uuid}`;
  return (
    <HitWrapper>
      {/* <pre>{JSON.stringify(hit, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify({ url }, null, 2)}</pre> */}
      <H4>{hit.jobTitle}</H4>
      {hit?.aiRequiredSkills && hit?.aiRequiredSkills?.length > 0 && (
        <PillsList>
          {hit?.aiRequiredSkills?.map((skill) => (
            <Pill isSmall={false} color={Colors.PrimaryDarkest}>
              {skill}
            </Pill>
          ))}
        </PillsList>
      )}
      <Spacer y={8} />
      {hit?.aiNiceToHaveSkills && hit?.aiNiceToHaveSkills?.length > 0 && (
        <PillsList>
          {hit?.aiNiceToHaveSkills?.map((skill) => (
            <Pill isSmall={true} color={Colors.Primary}>
              {skill}
            </Pill>
          ))}
        </PillsList>
      )}
      <Spacer y={8} />
      {hit?.aiSoftSkills && hit?.aiSoftSkills?.length > 0 && (
        <PillsList>
          {hit?.aiSoftSkills?.map((skill) => (
            <Pill isSmall={true} color={Colors.Contrast}>
              {skill}
            </Pill>
          ))}
        </PillsList>
      )}
      <Spacer y={8} />
      <HitActions>
        <Link to={url}>View</Link>
      </HitActions>
      {/* <Highlight hit={hit} attribute="name" className="Hit-label" /> */}
    </HitWrapper>
  );
}

const HitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border: 1px solid ${Colors.PrimaryLightest};
`;

const HitActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
