import { Suspense, lazy } from 'react';
import { toast } from 'react-toastify';

import { useUserContext } from '../../contexts/UserContext';
import { useRouter } from '../../hooks/useRouter';

import { useCreateJobPost } from '../../graph/mutations/createJobPost';

import { PrimaryButton } from '../../components/atoms/Button';
import { AddIcon } from '../../components/atoms/Icons';
import { Copy, H1 } from '../../components/atoms/Typography';
import { SmallLoader } from '../../components/atoms/Loader';
import { ErrorBoundary } from '../../components/molecules/ErrorBoundary';
import { formatUrl } from '../../utils/url';
import { Urls } from '../urls';
import { Colors } from '../../styles/colors';
import { SortDirection } from '../../generated/types';
import { useGetJobPosts } from '../../graph/queries/getJobPosts';
import { useActiveTab } from '../../hooks/useActiveTab';
import { TabController, TabPanel } from '../../components/atoms/Tabs';
import { InstantSearchWrapper } from '../../components/organisms/InstantSearch';

const JobsOfferTable = lazy(() => import('./JobsOfferTable.styles'));

export const JobPosts = () => {
  const user = useUserContext();
  const [createJobPost] = useCreateJobPost();
  const { navigate } = useRouter();
  const [activeTab, setActiveTab] = useActiveTab('all_job_posts');
  const { data: { jobPosts = [] } = {}, loading: jobsLoading } = useGetJobPosts({
    variables: {
      where: { userUuid: user?.uuid || '', isDeleted: false },
      sort: { direction: SortDirection.Desc, field: 'createdAt' },
    },
    fetchPolicy: 'cache-and-network',
  });

  async function handleJobOfferCreateClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    try {
      if (!user?.uuid) {
        throw new Error('User not found');
      }

      const result = await createJobPost({
        variables: {
          userUuid: user.uuid,
        },
      });

      if (result.data?.createJobPost?.uuid) {
        navigate(
          formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep1}`, {
            userUuid: user.uuid,
            jobUuid: result.data?.createJobPost?.uuid,
          }),
          {
            state: { from: `/${Urls.JobPosts}/#your_job_posts` },
          }
        );
      } else {
        throw new Error('Job Offer not created');
      }
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to create a Job Post at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
    }
  }

  async function handleEditJobPostClick(jobUuid: string) {
    if (!user?.uuid) {
      return;
    }
    navigate(
      formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep1}`, {
        userUuid: user.uuid,
        jobUuid,
      }),
      {
        state: { from: `/${Urls.JobPosts}/#your_job_posts` },
      }
    );
  }

  async function handleViewJobPostClick(jobUuid: string) {
    if (!user?.uuid) {
      return;
    }
    navigate(
      formatUrl(`/${Urls.JobPosts}/${Urls.JobPostView}`, {
        userUuid: user.uuid,
        jobUuid,
      }),
      {
        state: { from: `/${Urls.JobPosts}/#your_job_posts` },
      }
    );
  }

  return (
    <>
      <H1>Job Posts</H1>
      {/* <pre>{JSON.stringify({ jobPosts }, null, 2)}</pre> */}
      <>
        <TabController
          headers={[
            { id: 'all_job_posts', label: 'All Job Posts' },
            { id: 'your_job_posts', label: 'Your Job Posts' },
          ]}
          initialActiveId={activeTab}
          onTabChanged={(tabId) => {
            setActiveTab(tabId);
          }}
        >
          <TabPanel $tabId="all_job_posts">
            <InstantSearchWrapper />
          </TabPanel>
          <TabPanel $tabId="your_job_posts">
            <ErrorBoundary>
              <Copy marginBottom={32}>
                Ready to discover your next star candidate? Add a new job application, and we'll swiftly identify the
                perfect match for your role. Your next top talent is just a click away!
              </Copy>
              <Copy styleLevel={2} marginBottom={8}>
                Posting a Job Application on our platform is free.
              </Copy>
              <Copy styleLevel={2} marginBottom={32}>
                You only pay when you want to unlock the potential candidate's contact details.
              </Copy>

              <PrimaryButton
                iconRight={<AddIcon color={Colors.White} />}
                onClick={handleJobOfferCreateClick}
                size="medium"
                inline={true}
              >
                Add a New Job Post
              </PrimaryButton>

              <Suspense fallback={<SmallLoader />}>
                <ErrorBoundary>
                  {jobsLoading ? (
                    <SmallLoader />
                  ) : (
                    <JobsOfferTable
                      jobPosts={jobPosts}
                      handleViewClick={handleViewJobPostClick}
                      handleEditClick={handleEditJobPostClick}
                    ></JobsOfferTable>
                  )}
                </ErrorBoundary>
              </Suspense>
            </ErrorBoundary>
          </TabPanel>
        </TabController>
      </>
    </>
  );
};
