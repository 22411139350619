// https://codesandbox.io/s/github/algolia/instantsearch/tree/master/examples/react/default-theme?file=/src/App.tsx:4304-4346
import React from 'react';
import {
  InstantSearch,
  Breadcrumb,
  Configure,
  ClearRefinements,
  CurrentRefinements,
  DynamicWidgets,
  HierarchicalMenu,
  Highlight,
  Hits,
  HitsPerPage,
  InfiniteHits,
  Menu,
  Pagination,
  RangeInput,
  RefinementList,
  PoweredBy,
  SearchBox,
  SortBy,
  ToggleRefinement,
} from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';

import { Refresh } from './Refresh';
import { Colors } from '../../../styles/colors';
import { InstantSearchProps } from './InstantSearch.types';
import { Container, Hit } from './InstantSearch.styles';

const VITE_ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID;
const VITE_ALGOLIA_API_KEY = import.meta.env.VITE_ALGOLIA_API_KEY;
const INDEX = 'jobpost';

export const InstantSearchWrapper = ({
  children,
  isInstantSearch,
  onActionClick,
  ...otherProps
}: React.PropsWithChildren<InstantSearchProps>) => {
  const searchClient = algoliasearch(VITE_ALGOLIA_APP_ID, VITE_ALGOLIA_API_KEY);

  if (!VITE_ALGOLIA_APP_ID || !VITE_ALGOLIA_API_KEY) {
    return null;
  }

  return (
    <Container isInstantSearch={isInstantSearch} onClick={onActionClick} {...otherProps}>
      <InstantSearch searchClient={searchClient} indexName={INDEX} routing={true} insights={true}>
        <Configure ruleContexts={[]} />

        <ExtendedSearchBox placeholder="Enter relevant keywords to discover corresponding job postings" autoFocus />

        <Filter>
          <ExtendedHitsPerPage
            items={[
              { label: '20 hits per page', value: 20, default: true },
              { label: '40 hits per page', value: 40 },
            ]}
          />

          <ExtendedSortBy
            items={[
              { label: 'Relevance', value: 'instant_search' },
              // { label: 'Price (asc)', value: 'instant_search_price_asc' },
              // { label: 'Price (desc)', value: 'instant_search_price_desc' },
            ]}
          />

          <Refresh />
        </Filter>

        <ExtendedHits hitComponent={Hit} />

        <ExtendedPagination className="Pagination" />
      </InstantSearch>
    </Container>
  );
};

const Filter = styled.div`
  display: flex;
  padding: 16px 0;
  justify-content: space-between;
`;

const ExtendedHits = styled(Hits)`
  border: 1px solid ${Colors.ContrastLightest};
  ol {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
`;

const ExtendedSearchBox = styled(SearchBox)`
  input {
    border: 1px solid ${Colors.ContrastLightest};
    height: 48px;
    width: calc(100%);
    padding: 0 8px;
  }
  .ais-SearchBox-submit {
    display: none;
  }
  .ais-SearchBox-reset {
    display: none;
  }
`;

const ExtendedHitsPerPage = styled(HitsPerPage)`
  select {
    border: 1px solid ${Colors.ContrastLightest};
    height: 48px;
    padding: 0 8px;
  }
`;

const ExtendedSortBy = styled(SortBy)`
  select {
    border: 1px solid ${Colors.ContrastLightest};
    height: 48px;
    padding: 0 8px;
  }
`;

const ExtendedPagination = styled(Pagination)`
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 16px 0;
    gap: 32px;
    li {
      list-style: none;

      a {
        text-decoration: none;
        font-size: 16px;
        color: ${Colors.PrimaryDarkest};
      }

      span {
        color: ${Colors.PrimaryDarkest};
        font-size: 16px;
      }
    }
  }
`;
