import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateJobPostArgs } from '../../generated/types';

export const CREATE_JOB_POST = gql`
  mutation CreateJobPost($userUuid: String!) {
    createJobPost(userUuid: $userUuid) {
      uuid
    }
  }
`;

export const useCreateJobPost = (
  options?: MutationHookOptions<Pick<Mutation, 'createJobPost'>, MutationCreateJobPostArgs>
) =>
  useMutation<Pick<Mutation, 'createJobPost'>, MutationCreateJobPostArgs>(CREATE_JOB_POST, {
    // onCompleted: (data) => {
    //   console.log('onCompleted', data);
    // },
    ...options,
  });
